@mixin absoluteCover() {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.avinea {
    &__hero {
        //background: url('/images/hero.jpg') no-repeat bottom center / cover;
        height: 75vh;
        position: relative;

        @include mq(md) {
            height: 90vh;
            //background: url('/images/hero.jpg') no-repeat top center fixed / cover;
        }

        &__overlay {
            @include overlay();
        }

        &__back {
            background: url('/images/hero.jpg') no-repeat bottom center / cover;
            position: fixed;
            height: 75vh;
            width: 100%;
    
            @include mq(md) {
                height: 90vh;
            }
        }

        &__logo {
            position: absolute;
            width: 70%;
            max-width: 400px;
            margin-bottom: 18vh;
            z-index: 2;
            left: 0;
            right: 0;
            margin: auto;
            top: 43%;
            padding-bottom: 30px;

            img {
                display: block;
                width: 100%;
            }

            &:after {
                content: '';
                width: 1px;
                background: $darkGray;
                top: 100%;
                height: 5vh;
                left: 0;
                right: 0;
                margin: auto;
                display: block;
                position: absolute;

                @include mq(sm) {
                    height: 10vh;
                }

                @include mq(lg) {
                    height: 15vh;
                }
            }
        }

        &__scroll {
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            bottom: 3vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: $white;
            z-index: 2;

            span {
                font-size: 14px;
                font-weight: 500;
                margin-top: 10px;
                display: block;
                text-transform: uppercase;
            }

            img {
                animation: scrollDown 1s $easing infinite;
            }

            @keyframes scrollDown {
                0% {
                    opacity: 0;
                    transform: translateY(-20%);
                }

                100% {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }
    }

    &__about {
        @include padd;

        background: $mainColor;
        color: $white;
        position: relative;

    }

    &__world {
        @include padd;

        min-height: 75vh;
        background: $white url('/images/mundo.jpg') no-repeat top right;
        display: flex;
        align-items: flex-end;
        position: relative;
        background-size: 100%;

        @include customMq(370px, 760px) {
            min-height: 65vh;
        }

        @include mq(sm) {
            background: $white url('/images/mundo.jpg') no-repeat top right scroll;
            background-size: 75%;
        }

        @include mq(md) {
            min-height: 75vh;
        }

        @include customMq(1440px) {
            min-height: 100vh;
        }
    }

    &__philosophy {
        position: relative;

        @include mq(sm) {
            display: flex;
        }

        &__col-left,
        &__col-right {
            position: relative;
            @include mq(sm) {
                width: 50%;
            }
        }

        &__col-right {
            background: $mainColor;
            position: relative;
            padding: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            @include mq(sm) {
                padding: 60px;
            }

            &::before {
                position: absolute;
                top:15px;
                left:15px;
                right:15px;
                bottom:15px;
                border: 1px solid $darkestGray;
                content: '';

                @include mq(sm) {
                    top:30px;
                    left:30px;
                    right:30px;
                    bottom:30px;
                }
            }
        }

        &__data {
            max-width: 380px;
        }

        &__features {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            li {
                width:100%;
                margin-bottom: 10%;
                color: $white;
                text-align: center;
                
                @include mq(sm) {
                    width: 45%;

                    &:nth-child(3),
                    &:nth-child(4) {
                        margin-bottom: 0;
                    }
                }

                
            }

            strong {
                font-weight: 900;
                font-family: $titleFont;
                display: block;
                font-size: 42px;
            }

            span {
                font-size: 14px;
                text-transform: uppercase;
                line-height: 1.8;
            }
        }

        &__image {
            @include absoluteCover();

            img {
                position: absolute;
                height: 100%;
                min-width: 100%;
                right:0;
                top:0;
            }
        }
    }

    &__sustainability {
        position: relative;
        background: $white;

        @include mq(sm) {
            display: flex;
        }

        &__col-left,
        &__col-right {
            @include mq(sm) {
                width: 50%;
            }
        }

        &__col-left {
            padding: 12%;
            text-align: center;
            background: $white;

            @include mq(sm) {
                padding: 7%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }

        &__col-right {
            @include mq(sm) {
                display: flex;
                flex-direction: column;
            }
        }

        &__icon {
            margin-bottom: 30px;
        }

        &__content {
            text-align: left;
            width: 90%;
            margin: auto;
            font-size: 14px;
            line-height: 1.8;

            @include mq(sm) {
                width: 75%;
            }

            strong {
                margin-bottom: 20px;
                display: block;
            }

            ul {
                list-style-type: disc;
                list-style-position: inside;

                li {
                    margin-bottom: 15px;
                }
            }
        }

        &__image {
            width: 100%;
            padding-bottom: 65%;
            height: 0;
            overflow: hidden;
            position: relative;

            img {
                position: absolute;
                width: 100%;
                min-height: 100%;
                left:0;
                top:0;
            }
        }

        &__certificates {
            background: $lightestGray;
            padding: 12%;
            display: flex;
            flex-direction: column;
            flex-grow: 2;
            justify-content: center;
            align-items: center;

            @include mq(sm) {
                padding: 10%;
                flex-direction: row;
            }

            @include mq(md) {
                padding: 8%;
            }
        }

        &__certificates-logos {
            order: 2;
            text-align: center;

            @include mq(sm) {
                order: 1;
            }

            img {
                width: 50px;
                display: inline-block;

                &:not(:first-child) {
                    margin-left: 15px;
                }
                
                @include mq(sm) {
                    margin-left:auto;
                    margin-bottom: 30px;
                    display: block;
                    margin-right:auto;
                }
            }
        }

        &__certificates-text {
            text-align: left;
            font-size: 14px;
            line-height: 1.8;
            order: 1;
            
            @include mq(sm) {
                padding-left: 50px;
                order: 2;
            }

            strong {
                margin-bottom: 0;
                display: block;
            }

            ul {
                list-style-type: disc;
                margin-bottom: 20px;
                list-style-position: inside;

                li {
                    margin-bottom: 0px;
                }
            }
        }
    }

    &__productivity {
        position: relative;

        &__heading {
            @include padd;

            background: $mainColor;
            position: relative;
            color: $white;
            text-align: center;
            padding-left: 15px;
            padding-right: 15px;

            h2 {
                position: relative;
                font-size: 24px;

                @include mq(sm) {
                    font-size: 42px;
                }

                span {
                    font-size: 0.5em;
                    display: inline-block;
                    transform: translateY(-10px);
                }
            }

            p {
                position: relative;
                font-size: 14px;
            }

            &:before {
                @include absoluteCover();

                content: '';
                background: url('/images/produccion-heading.jpg') no-repeat center center / cover;
                opacity: .2;
                filter: grayscale(100%);
            }
        }

        &__body {
            @include padd(bottom);
            text-align: center;
            background: $white;

            @include mq(sm) {
                position: relative;
                padding-top: 17%;
            }
        }

        &__map {
            @include mq(sm) {
                position: absolute;
                width: 100%;
                z-index: 0;
                top: 0;
            }

            img {
                width: 100%;
            }
        }

        &__map-markers {
            display: none;

            // @include mq(sm) {
            //     display: block;
            //     position: absolute;
            //     width: 20%;
            //     left: 50%;
            //     height: 0;
            //     padding-bottom: 23%;
            //     top: 58%;
            //     transform: translate3d(calc(-28% - 400px), 0, 0);

            //     img {
            //         position: absolute;

            //         &:first-child {
            //             width: 80%;
            //             left: 0;
            //         }

            //         &:last-child {
            //             width: 50%;
            //             left: -3%;
            //             top: 15%;
            //         }
            //     }
            // }
        }

        &__data {
            font-size: 14px;
            text-align: center;
            padding: 15px 0;

            @include mq(sm) {
                text-align: left;
            }
        }

        &__featured {
            text-align: left;
            margin-bottom: 30px;

            strong,
            ul {
                font-size: 14px;
                margin-bottom: 15px;
                list-style-type: disc;
                display: block;
                list-style-position: inside;

                li {
                    margin-bottom: 5px;
                }
            }
        }

        &__data-row {
            padding-top: 6%;
            padding-bottom: 6%;

            .col-xs-12.col-sm-4:first-child {
                @include mq(sm) {
                    position: absolute;
                    left: -39%;
                    bottom: -10%;

                    .avinea__productivity__data {
                        text-align: right;
                    }
                }

                @include mq(lg) {
                    position: absolute;
                    left: calc(-12% - 295px);
                    bottom: -40%;
                }
            }
        }
    }

    &__brands {
        position: relative;
        background: $mainColor; 

        &__heading {
            @include padd;
            
            background: $mainColor;
        }

        &__selector {
            @include trans;

            background: $white;
            height: 0;
            width: 100%;
            padding-bottom: 60%;
            position: relative;
            cursor: pointer;
            padding-left: 15%;
            padding-right: 15%;
            min-height: 75px;
            
            img{
                @include trans;

                display: block;
                position: absolute;
                left:50%;
                top: 50%;
                transform: translate3d(-50%, -50%, 0);
                max-height: 60%;
                max-width: 60%;
                opacity: .8;
            }

            &.-active {
                background: $mainColor;

                img {
                    filter: grayscale(100%) brightness(1000%) contrast(200%);
                    opacity: 1;
                }
            }
        }

        &__selector-col {
            border-right: 1px solid $lightGray;
            border-bottom: 1px solid $lightGray;
            

            &:nth-child(3),
            &:nth-child(6) {
                border-right: none;
            }

            &:nth-child(3) {
                @include mq(sm) {
                    border-right: 1px solid $lightGray;
                }
            }

            &:nth-child(6) {
                @include mq(sm) {
                    border-right: 1px solid transparent;
                }
            }

            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6) {
                border-bottom: none;
            }

            @include mq(sm) {
                border-bottom: none;
            }
        }        

        &__container {
            position: relative;
            height: 70vh;
        }

        &__content {
            @include trans;

            opacity: 0;
            height: 100%;
            width: 100%;
            position: absolute;
            top:0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            &:after {
                @include overlay;
                content: '';
            }

            &.-active {
                opacity: 1;
            }

            &:nth-child(1) {
                background: url('/images/brand1.jpg') no-repeat center center / cover;
            }
            &:nth-child(2) {
                background: url('/images/brand2.jpg') no-repeat center center / cover;
            }
            &:nth-child(3) {
                background: url('/images/brand3.jpg') no-repeat center center / cover;
            }
            &:nth-child(4) {
                background: url('/images/brand4.jpg') no-repeat center center / cover;
            }
            &:nth-child(5) {
                background: url('/images/brand5.jpg') no-repeat center center / cover;
            }
            &:nth-child(6) {
                background: url('/images/brand6.jpg') no-repeat center center / cover;
            }
        }

        &__data {
            @include trans;

            position: absolute;
            max-width: 450px;
            color: $white;
            z-index: 5;
            text-align: center;
        }

        &__content-image {
            @include trans;

            display: inline-block;
            max-height: 60%;
            max-width: 60%;
            margin-bottom: 30px;

            img{
                width: 100%;
                filter: grayscale(100%) brightness(1000%) contrast(200%);
            }
        }
    }

    &__contact{
        @include padd;

        position: relative;
        background: $white;

        &__item {
            @include mq(sm) {
                text-align: center;
                padding: 10% 0;
            }

            h3 {
                font-family: $titleFont;
                font-weight: 900;
            }
        }
    }

    &__text {
        font-size: 14px;
        line-height: 1.8;

        &--capitular {
            &:first-letter {
                float:left;
                color:$white;
                font-size:80px;
                line-height:60px;
                padding:2px;
                margin-right: 5px;   
                font-weight: 700;
            }
        }
    }

    &__section-title {
        font-family: $titleFont;
        position: relative;
        text-align: center;
        padding-bottom: 30px;
        margin: 0;
        margin-bottom: 40px;
        color: $mainColor;
        font-weight: 900;
        font-size: 36px;

        @include mq(sm) {
            padding-bottom: 30px;
            margin-bottom: 50px;
        }

        @include mq(md) {
            font-size: 56px;
            padding-bottom: 40px;
            margin-bottom: 60px;
        }

        &:after{
            content: '';
            width: 40px;
            height: 2px;
            background: $mainColor;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            bottom: -1px;
        }

        &--white {
            color: $white;

            &:after{
                background: $white;
            }
        }

        &--left {
            text-align: left;

            &:after{
                right: auto;
            }
        }

        &--small {
            font-size: 24px;
            padding-bottom: 15px;
            margin-bottom: 25px;

            @include mq(sm) {
                font-size: 32px;
                padding-bottom: 20px;
                margin-bottom: 30px;
            }
        }

        &--no-deco {
            margin-bottom: 0;
            padding-bottom: 0;

            &:after {
                display: none;
            }
        }
    }

    &__button {
        @include trans;

        border: 1px solid $lightGray;
        background: transparent;
        appearance: none;
        height: 50px;
        max-width: 300px;
        line-height: 50px;
        cursor: pointer;
        color: $white;
        display: block;
        width: 100%;
        margin: 0 auto 15px;
        text-transform: uppercase;
        font-weight: 500;

        @include mq(sm) {
            margin: 0 15px;
        }

        &:hover {
            color: $midGray;
        }
    }

    &__intro {
        @include absoluteCover();
        @include trans($d: 1s);

        position: fixed;
        z-index: 20000;
        opacity: 1;
        pointer-events: none;
        transform: translateY(-100%);
        background: $mainColor url('/images/intro.jpg') no-repeat center center / cover;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        
        &.-visible {
            opacity: 1;
            pointer-events: all;
            transform: translateY(0%);
        }

        &__title,
        &__subtitle {
            text-transform: uppercase;
            color: $white;
        }

        &__title {
            font-size: 21px;
        }

        &__subtitle {
            font-size: 14px;
        }

        &__logo {
            width: 90%;
            margin: auto;
            max-width: 200px;
            margin-bottom: 50px;

            img {
                max-width: 100%;
            }
        }

        &__actions {
            margin-top: 50px;

            @include mq(sm) {
                display: flex;
            }
        }

        &__disclaimer {
            color: $white;
            position: absolute;
            max-width: 400px;
            width: 90%;
            bottom: 30px;
            left: 0;
            right: 0;
            margin: auto;
            font-size: 12px;
        }
    }
}
