.footer {
    @include padd(both, half);

    background: $mainColor;
    color: $midGray;
    text-align: center;
    position: relative;

    &__logo {
        width: 70%;
        max-width: 300px;
        margin: 0 auto 30px;

        img{
            width: 100%;
        }
    }

    &__menu {
        @include mq(sm) {
            display: flex;
            justify-content: center;
        }
        li {
            margin-bottom: 15px;
            margin-left: 15px;
            margin-right: 15px;
        }
        a {
            color: $midGray;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 14px;
        }
    }

    &__credits {
        text-transform: uppercase;
        font-size: 12px;
        display: block;
        color: $midGray;

        

        &__col {
            @include mq(sm) {
                text-align: left;
                order: 1;
            }
        }
    }

    &__copy {
        font-size: 11px;
        margin-bottom: 30px;

        @include mq(sm) {
            margin-bottom: none;
        }

        &__col {
            @include mq(sm) {
                order: 2;
                text-align: center;
            }
        }
    }

    &__menu {
        @include padd(both, half);
    }

    &__disclaimer-row {
        @include mq(sm) {
            display: flex;
        }
    }

    &__logos {
        margin-bottom: 30px;

        @include mq(sm) {
            margin-bottom: none;
        }
        
        img {
            display: inline-block;
            margin: 0 10px;
            filter: grayscale(100%) brightness(1000%);
            opacity: 1;
            height: 40px;

            &:first-child {
                opacity: .2;
            }
        }

        &__col {
            flex-shrink: 0;
            
            @include mq(sm) {
                order: 3;
                text-align: right;
            }
        }

       
    }
}
