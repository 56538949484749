.header {

    $h: &;

    position: fixed;
    top: 0;
    left:0;
    right:0;
    width: 100%;
    background: $white;
    height: 50px;
    z-index: 10000;

    @include mq(md) {
        height: 70px;
    }

    .container,
    .col-24,
    .row {
        height: 100%;
    }

    &__wrapper {
        height: 100%;

        @include mq(md) {
            display: flex;
            justify-content: space-between;
        }
    }

    &__logo {
        height: 50px;
        display: flex;
        align-items: center;

        @include mq(md) {
            height: 70px;
        }

        img {
            height: 20px;

            @include mq(md) {
                height: 25px;
            }
        }
    }

    &__nav {
        @include trans;

        position: fixed;
        top: 50px;
        background: $mainColor;
        bottom: 0;
        left: 0;
        right: 0;
        transform: translateX(-100%);

        @include mq(md) {
            transform: translate(0);
            position: static;
            display: flex;
            justify-content: flex-end;
            background: transparent;
            align-items: center;
        }
    }

    &__menu {
        
        @include mq(md) {
            display: flex;
            flex-grow: 2;
        }

        li {
            border-bottom: 1px solid $darkestGray;

            @include mq(md) {
                border-bottom: none;
            }
        }

        a {
            height: 50px;
            line-height: 50px;
            padding: 0 15px;
            display: block;
            color: $white;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 500;
            white-space: nowrap;
            position: relative;

            @include mq(md) {
                font-size: 11px;
                color: $mainColor;
                height: 70px;
                line-height: 80px;
                padding: 0 10px;
            }

            @include mq(lg) {                
                font-size: 14px;
                padding: 0 15px;
            }

            &:after {
                @include mq(md) {   
                    @include trans;

                    position: absolute;
                    content: '';
                    height: 2px;
                    left: 50%;
                    right: 50%;
                    bottom: 10px;
                    background: $darkGray;
                }
            }

            &:hover {
                &:after {
                    @include mq(md) {   
                        left: 0%;
                        right: 0%;
                    }
                }
            }
        }
    }

    &__language {
        height: 60px;
        border: none;
        width: 95%;
        padding-left: 5px;
        color: $white;
        font-size: 14px;
        font-weight: 500;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: $mainColor url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAYAAADEUlfTAAAAG0lEQVR42mNgwAfKy8v/48I4FeA0AacVDFQBAP9wJkE/KhUMAAAAAElFTkSuQmCC') no-repeat 100% 50%;
        padding-right:20px;
        text-transform: uppercase;

        @include mq(md) {
            height: 30px;
            border: 1px solid $lightGray;
            color: $mainColor;
            width: 60px;
            background: $white url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAYAAADEUlfTAAAAG0lEQVR42mNgwAfKy8v/48I4FeA0AacVDFQBAP9wJkE/KhUMAAAAAElFTkSuQmCC') no-repeat 90% 50%;
            transform: translateY(4px);
        }

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    &__hamburguer {
        cursor: pointer;
        width: 25px;
        height: 20px;
        margin: auto;
        position: absolute;
        right: 15px;
        top: 0;
        bottom: 0;

        span {
            background-color: $mainColor;
            border-radius: 2px;
            content: '';
            display: block;
            width: 100%;
            height: 2px;

            &:nth-child(1) {
                animation:outT 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(2) {
                margin: 7px 0;
                animation:outM 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(3) {
                animation:outBtm 0.6s $easing backwards;
                animation-direction:reverse;
            }
        }

        @include mq(md) {
            display: none;
        }
    }

    &.-open {
        #{$h}__hamburguer {
            span:nth-child(1) {
                animation:inT 0.6s $easing forwards;
            }
            span:nth-child(2) {
                animation:inM 0.6s $easing forwards;
            }
            span:nth-child(3) {
                animation:inBtm 0.6s $easing forwards;
            }
        }

        #{$h}__nav {
            transform: translateX(0);
        }
    }
}

//hamburguer animations
@keyframes inM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes outM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes inT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes outT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes inBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
@keyframes outBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
